import React from "react"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"

function DatePickerCustom(props) {
  const { isOpen, onOpen, onClose, label } = props
  // const defaultMaterialTheme = createMuiTheme({
  //   palette: {
  //     primary: {
  //       main: color || red["A100"],
  //     },
  //   },
  // })

  return (
    // <ThemeProvider theme={defaultMaterialTheme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        open={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        label={label}
        format="dd MMM yyyy"
        {...props}
        // InputProps={{
        //   ...InputProps,
        //   style: {
        //     ...InputProps.style,
        //     color: "black",
        //     cursor: "pointer",
        //     fontFamily:  font ? font : "MabryProLight",
        //   },
        // }}
        // inputProps={{ style: { cursor: "pointer" } }}
      />
    </MuiPickersUtilsProvider>
    // </ThemeProvider>
  )
}

export default DatePickerCustom
