const getTotalExtras = array => {
  const sumFunc = value => {
    accumulator = accumulator + (value.selected && parseFloat(value.price))
  }
  let accumulator = 0
  if (array?.lenght > 0) {
    array.forEach(sumFunc)
  }
  return accumulator
}

const getTotalEur = (
  adults,
  myChildren,
  perPersonPricing,
  childPricingPercentageToAdult,
  incrementalExtras,
  fixedExtras,
  isFixedTour,
  fixedTourPrice,
  isFixedPriceTour
) => {
  const perPersonExtras = getTotalExtras(incrementalExtras)
  const variablePriceTotal =
    adults * (perPersonPricing + perPersonExtras) +
    myChildren *
      (((perPersonPricing + perPersonExtras) * childPricingPercentageToAdult) /
        100) +
    (adults || myChildren ? (fixedExtras ? getTotalExtras(fixedExtras) : 0) : 0)
  const fixedPriceTotal = isFixedTour ? fixedTourPrice : 0
  const newTotal = isFixedPriceTour ? fixedPriceTotal : variablePriceTotal
  return newTotal
}

export default getTotalEur
