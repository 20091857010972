import { makeStyles } from "@material-ui/core/styles"

const useAppbarStyles = makeStyles(theme => ({
  barLook: {
    backgroundColor: "white",
    boxShadow: "none",
  },
  grow: {
    flexGrow: 1,
  },
  navFirst: {
    color: "black",
    fontSize: 18,
    textDecoration: "none",
  },
  dBlock: {
    display: "flex",
    flexDirection: "column",
  },
  nav: {
    color: "black",
    fontSize: 18,
    marginLeft: 20,
    textDecoration: "none",
  },
  navLast: {
    color: "black",
    fontSize: 18,
    marginLeft: 20,
    marginRight: 20,
    textDecoration: "none",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 90,
  },
  select: {
    "&:before": {
      borderColor: "white",
    },
    "&:after": {
      borderColor: "white",
    },
  },
  btn: {
    borderRadius: "2px",
    fontSize: "12px",
    textTransform: "capitalize",
  },
  arrow: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
    },
    transition: "all 0.3s ease",
  },
}))
export default useAppbarStyles
