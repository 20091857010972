import { graphql } from "gatsby"
import Img from "gatsby-image"
import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Container,
  Hidden,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Checkbox from "@material-ui/core/Checkbox"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import InputAdornment from "@material-ui/core/InputAdornment"
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles"
import zhutravelTheme from "../components/Layout/zhutravelTheme"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import { CalendarTodayOutlined } from "@material-ui/icons"
import ScheduleIcon from "@material-ui/icons/Schedule"
import ArrowForward from "@material-ui/icons/ArrowForward"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Alert from "@material-ui/lab/Alert"
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { navigate } from "gatsby"
import moment from "moment"
import React, { useState } from "react"
import { IntlProvider } from "react-intl"
import "react-multi-carousel/lib/styles.css"
import CheckoutAppbar from "../components/CheckoutAppbar"
import DatePickerCustom from "../components/DatePicker"
import Layout from "../components/Layout"
import { useSelector } from "react-redux"
import Axios from "axios"
import { BASE_URL } from "../config/api"
import PeopleMenu from "../templates/tourDetail/PeopleMenu"
import getTotalEur from "../templates/tourDetail/getTotalEur"
import { SEO } from "../components/SEO"
const checkoutTheme = createMuiTheme(zhutravelTheme, {
  palette: {
    primary: {
      main: "#FF9486",
    },
    text: {
      disabled: zhutravelTheme.palette.text.primary,
    },
  },
  props: {
    MuiInputBase: {
      fullWidth: true,
    },
    MuiSelect: {
      IconComponent: ExpandMoreIcon,
    },
  },
  overrides: {
    MuiTypography: {
      h1: {
        [zhutravelTheme.breakpoints.up("xs")]: {
          fontSize: "2.5rem",
        },
      },
      h2: {
        fontFamily: "MabryProRegular",
        fontSize: "1.25rem",
        lineHeight: "26px",
        paddingTop: zhutravelTheme.spacing(7),
        paddingBottom: "32px",
        [zhutravelTheme.breakpoints.up("md")]: {
          paddingTop: zhutravelTheme.spacing(10),
        },
      },
      h3: {
        fontFamily: "MabryProLight",
        fontSize: "0.875rem",
        lineHeight: "18px",
      },
      overline: {
        fontFamily: "MabryProLight",
      },
    },
    MuiInput: {
      underline: {
        "&:before": {
          borderBottomColor: zhutravelTheme.palette.divider,
        },
      },
      formControl: {
        "label + &": {
          marginTop: zhutravelTheme.spacing(1),
        },
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: "MabryProRegular",
        paddingTop: "16px",
        alignItems: "start",
        cursor: "pointer",
      },
      input: {
        paddingTop: 0,
        paddingBottom: "13px",
      },
      multiline: {
        paddingTop: "16px",
        paddingBottom: "13px",
      },
    },
    MuiInputAdornment: {
      root: {
        paddingRight: "8px",
        height: "unset",
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
    MuiCardContent: {
      root: {
        paddingLeft: zhutravelTheme.spacing(3),
        paddingRight: zhutravelTheme.spacing(3),
        paddingTop: zhutravelTheme.spacing(4),
        paddingBottom: zhutravelTheme.spacing(4),
      },
    },
    MuiButton: {
      contained: {
        backgroundColor: "#FF9A8B",
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: "0.875rem",
      },
      asterisk: {
        color: "#FF9486",
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: "translate(0, 1.5px)",
      },
    },
    MuiFormGroup: {
      root: {
        flexDirection: "row",
        justifyContent: "space-between",
      },
    },
  },
})
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(12.5),
    },
    "& a": {
      color: "#FF9486",
      textDecoration: "underline",
    },
  },
  card: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.up("md")]: {
      position: "sticky",
      top: 20,
      marginTop: theme.spacing(0),
    },
  },
  cardSection: {
    paddingTop: theme.spacing(4),
  },
  inputHelper: {
    fontSize: 14,
    fontFamily: "MabryProRegular",
    color: checkoutTheme.palette.primary.main,
    flexShrink: 0,
  },
  currency: {
    fontSize: 14,
    fontFamily: "MabryProRegular",
    flexShrink: 0,
  },
  extrasLabel: {
    paddingBottom: "4px",
  },
  unitPrice: {
    fontFamily: "MabryProLight",
    fontSize: 14,
    paddingTop: 0,
  },
  totalWrapper: {
    display: "flex",
    alignItems: "flex-end",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1),
  },
  vat: {
    paddingTop: theme.spacing(1.5),
    fontSize: "0.875rem",
  },
  total: {
    fontFamily: "MabryProRegular",
    fontSize: "1.5rem",
    lineHeight: "26px",
    paddingRight: theme.spacing(0.5),
  },
  peopleIndex: {
    fontFamily: "MabryProRegular",
  },
  fineprint: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    fontSize: "0.875rem",
  },
  personalInfoHelper: {
    fontSize: "0.875rem",
  },
  checkboxLabel: {
    fontFamily: "MabryProRegular",
  },
  logo: {
    fontFamily: "MabryProBold",
    fontWeight: "bold",
    fontSize: "1.5rem",
    flexGrow: 1,
  },
  media: {
    height: 276,
  },
  cardimage: {
    maxHeight: 500,
    width: 600,
  },
  textField: {
    marginRight: theme.spacing(3),
    marginTop: 32,
  },
  margin: {
    margin: theme.spacing(1),
  },
  textWhite: {
    color: "white",
  },
  indicator: {
    backgroundColor: "white",
  },
  tabRoot: {
    "&:hover": {
      color: "white",
    },
    "&$tabSelected": {
      color: "white",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "white",
    },
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      margin: "auto",
      maxWidth: 500,
    },
    image: {
      height: 50,
    },
    img: {},
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  labeltext: {
    fontFamily: "MabryProLight",
  },
  input: {
    fontFamily: "MabryProRegular",
  },
  focusedLabel: {},
  erroredLabel: {},
  arrow: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
    },
    transition: "all 0.3s ease",
  },
}))
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: { fontFamily: "MabryProRegular" },
  },
}
const CheckOut = ({ location, pageContext, data: queryData }) => {
  const countries = queryData.allCountry.edges.map(({ node }) => node) //List of all countries from https://restcountries.com/v2/all
  const [countryCode, setCountryCode] = React.useState(null)
  const selectedCountry = countries.filter(
    country => country.alpha3Code === countryCode
  )[0]
  const paymentIcons = [
    queryData.stripe,
    queryData.visa,
    queryData.mastercard,
    queryData.amex,
  ]
  const locale = useSelector(state => state?.locale?.localeObj?.locale)
  const translations = useSelector(
    state => state?.locale?.localeObj?.pageData?.tourDetail
  )
  const pageTranslations = useSelector(
    state => state?.locale?.localeObj?.pageData?.checkout
  )
  const mdUp = useMediaQuery(zhutravelTheme.breakpoints.up("md"))
  const classes = useStyles()
  const [slot, setSlot] = useState(location?.state?.time)
  const [data, setData] = useState({
    total: location?.state?.total,
    title: location?.state?.title,
    children: location?.state?.children,
    adults: location?.state?.adults,
    infants: location?.state?.infants,
    allSelectedExtras: location?.state?.allSelectedExtras,
    personsTotal: location?.state?.personsTotal,
    date: location?.state?.date,
    time: location?.state?.time,
    timesarray: location?.state?.timesarray,
    disableWeekDays: location?.state?.datesarray?.disableWeekDays,
    disableDates: location?.state?.datesarray?.disableDates,
    disableDaysLimit: location?.state?.datesarray?.disableDaysLimit,
    isDisableCalender: location?.state?.datesarray?.isDisableCalender,
    couponCode: location?.state?.couponCode,
    totalPersonsLimit: location?.state?.totalPersonsLimit,
    tourAvailability: location?.state?.tourAvailability,
  })
  const [info, setInfo] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [paymentType, setPaymentType] = useState("card")
  const stripe = useStripe()
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenSlots, setIsOpenSlots] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [chineseBookingComplete, setCHineseBookingComplete] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [couponError, setCouponError] = useState("")
  const [couponDiscount, setCouponDiscount] = useState(0)
  const [couponCode, setCouponCode] = useState("")
  const couponCodeActual = location?.state?.couponCode
  const couponDiscountActual = location?.state?.couponDiscount
  const [total, setTotal] = useState(location?.state?.total)
  // eslint-disable-next-line no-unused-vars
  const [previousTotal, setPreviousTotal] = useState(location?.state?.total)

  // Destructuring together causes webpack build error
  const myData = location?.state
  const perPersonPricing = myData?.perPersonPricing
  const incrementalExtras = myData?.incrementalExtras
  const childPricingPercentageToAdult = myData?.childPricingPercentageToAdult
  const fixedExtras = myData?.fixedExtras
  const isFixedTour = myData?.isFixedTour
  const fixedTourPrice = myData?.fixedTourPrice
  const isFixedPriceTour = myData?.isFixedPriceTour
  const [myAdults, setAdults] = useState(data.adults)
  const [myChildren, setChildren] = useState(data.children)
  const [myInfants, setInfants] = useState(data.infants)
  const handleTime = e => {
    setSlot(e.target.value)
    setIsOpenSlots(false)
  }
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
  })
  const elements = useElements()
  const handlechange = name => event => {
    setState({ ...state, [name]: event.target.checked })
  }
  const handleCountryChange = ({ target: { value } }) => {
    setCountryCode(value)
    const country = countries.filter(country => country.alpha3Code === value)[0]
    setInfo({
      ...info,
      country: country.name,
    })
  }

  const confirmBooking = async details => {
    try {
      //Connect to the API and send the tour details to update the database
      Axios.post(`${process.env.API_URL}/confirm`, {
        info: {
          tour: data.title,
          schedule: data.time,
          date: data.date.toDateString(),
          limit: data.totalPersonsLimit,
          confirmed: data.children + data.adults + data.infants,
        },
      })
      sendEmail(details) //Call the sendEmail function to send the email
    } catch (error) {
      console.log(error)
    }
  }

  const handleBookNow = async () => {
    setLoading(true)
    const card = elements.getElement(CardElement)
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: paymentType,
      card,
    })
    if (error) {
      setLoading(false)
      setError(error.message)
    } else {
      setSuccess("Data Confirmed!, Processing...")
      handleAPI(paymentMethod)
    }
  }
  const handleAPI = paymentMethod => {
    const { type, id } = paymentMethod
    const {
      total,
      children,
      title,
      infants,
      adults,
      allSelectedExtras,
      personsTotal,
      date: selectedDate,
      time,
      couponCode,
    } = data
    const details = {
      contactInformation: info,
      bookingDetails: {
        children,
        title,
        infants,
        adults,
        allSelectedExtras,
        personsTotal,
        selectedDate,
        time,
        couponCode,
      },
    }
    Axios.post(BASE_URL + "/book", {
      paymentDetails: {
        amount: total,
        payment_method: id,
        payment_method_types: [type],
        currency: "EUR",
      },
    })
      .then(({ data }) => {
        setSuccess(data.message)
        return confirmPayment(type, data.client_secret)
      })
      .then(({ paymentIntent }) => {
        if (paymentIntent.status === "succeeded") {
          setSuccess("Payment successfully done")
          confirmBooking(details) //Call the confirmBooking function to update the database
            .then(() => {
              navigate(
                `/OrderComplete?total=${total}&tour=${
                  data.title
                }&date=${data.date.toDateString()}&slot=${slot}&adults=${myAdults}&children=${myChildren}&infants=${myInfants}`
              )
            })
            .catch(error => {
              setError("Email could be send please contact us direclty")
              console.log(error)
            })
        }
      })
      .catch(err => {
        setError(err)
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const sendEmail = emailFields => {
    try {
      return Axios.post(BASE_URL + "/email", {
        subject: "Tour confirmation",
        html: JSON.stringify(emailFields),
      })
    } catch (error) {
      console.log(error)
    }
  }

  const confirmPayment = (payment_type, secret) => {
    switch (payment_type) {
      case "card":
        return stripe.confirmCardPayment(secret)
      case "alipay":
        return stripe.confirmAlipayPayment(secret, {
          return_url: `${
            process.env.WEBPAGE_URL
          }/OrderComplete?total=${total}&tour=${
            data.title
          }&date=${data.date.toDateString()}&slot=${slot}&adults=${myAdults}&children=${myChildren}&infants=${myInfants}&limit=${
            data.totalPersonsLimit
          }`,
        })
      case "wechat_pay":
        return stripe.confirmWechatPayPayment(secret, {
          payment_method_options: {
            wechat_pay: {
              client: "web",
            },
          },
        })
      default:
        setError("payment type is not handled")
    }
  }
  const isDisableDay = day => {
    const isDisable =
      !!data.disableDaysLimit &&
      data.disableDaysLimit > 0 &&
      Array.from({ length: data.disableDaysLimit + 1 })
        .map((__, index) => {
          const today = new Date()
          const disablingDay = new Date(today)
          disablingDay.setDate(disablingDay.getDate() + index)
          return disablingDay.toDateString() === day.toDateString()
        })
        .filter(disable => disable).length > 0
    const isAnyWeekDayDisable =
      data.disableWeekDays.length > 0
        ? data.disableWeekDays.filter(disabledWeekday => {
            return day.getDay() === disabledWeekday
          }).length > 0
        : false
    const isDisableDate =
      data.disableDates.length > 0
        ? data.disableDates.filter(eachDate => {
            if (!!eachDate) {
              return (
                moment(eachDate).format("MM-DD") === moment(day).format("MM-DD")
              )
            } else return false
          }).length > 0
        : false
    if (isDisable || isAnyWeekDayDisable || isDisableDate) {
      return true
    } else {
      return false
    }
  }
  const checkCoupon = () => {
    if (couponCode === couponCodeActual) {
      if (!couponDiscount) {
        const actualTotal = total - (total * couponDiscountActual) / 100
        setCouponDiscount(couponDiscountActual)
        setTotal(actualTotal)
        setCouponError("")
      }
    } else {
      if (couponDiscount > 0) {
        setCouponDiscount(0)
        setTotal(previousTotal)
        setCouponError("Wrong Coupon Code Entered!")
      } else {
        setCouponError("Wrong Coupon Code Entered!")
      }
    }
  }
  if (location?.state) {
    return (
      <Layout
        isHeaderDisable={true}
        location={location}
        pageContext={pageContext}
      >
        <SEO />
        <div className={locale === "zh" && "chinese-version"}>
          <IntlProvider>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={success.length}
              autoHideDuration={6000}
              onClose={() => {
                setSuccess("")
              }}
            >
              <Alert
                onClose={() => {
                  setSuccess("")
                }}
                severity="success"
              >
                {success}
              </Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={error.length}
              autoHideDuration={6000}
              onClose={() => setError("")}
            >
              <Alert onClose={() => setError("")} severity="error">
                {error}
              </Alert>
            </Snackbar>
            <div style={{ backgroundColor: "#FAF5EF" }}>
              <CheckoutAppbar backgroundcolor={"#FAF5EF"} />
              <ThemeProvider theme={checkoutTheme}>
                <Container className={classes.root}>
                  <Grid container wrap="wrap-reverse">
                    <Grid item xs={false} md={1} />
                    <Grid item xs={12} md={4}>
                      <Hidden smDown>
                        <Typography variant="h1">
                          {pageTranslations?.checkout}
                        </Typography>
                      </Hidden>
                      <Typography variant="h2">Billing & Contact</Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            shrink
                            id="standard-required"
                            required
                            label="First Name"
                            fullWidth
                            placeholder={pageTranslations?.typeFirstName}
                            value={info.firstName}
                            onChange={e =>
                              setInfo({ ...info, firstName: e.target.value })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            id="standard-required"
                            required
                            label="Last Name"
                            placeholder={pageTranslations?.typeLastName}
                            value={info.lastName}
                            onChange={e =>
                              setInfo({ ...info, lastName: e.target.value })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="standard-required"
                            required
                            label={pageTranslations?.emailAddress}
                            placeholder={pageTranslations?.typeEmail}
                            fullWidth
                            value={info.email}
                            onChange={e =>
                              setInfo({ ...info, email: e.target.value })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="standard-required"
                            required
                            select
                            label={"Where are you from?"}
                            value={countryCode}
                            onChange={handleCountryChange}
                            InputLabelProps={{ shrink: true }}
                          >
                            {queryData.allCountry.edges.map(({ node }) => (
                              <MenuItem
                                key={node.alpha3Code}
                                value={node.alpha3Code}
                              >
                                {node.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            id="standard-required"
                            label={"Code"}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            disabled
                            value={selectedCountry?.callingCodes}
                          >
                            {selectedCountry?.callingCodes}
                          </TextField>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            required
                            label={"Phone"}
                            value={info.phone}
                            onChange={e =>
                              setInfo({ ...info, phone: e.target.value })
                            }
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="number"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="standard-required"
                            required
                            multiline
                            label={"Anything else you would like to tell us?"}
                            value={info.opinion}
                            onChange={e =>
                              setInfo({ ...info, opinion: e.target.value })
                            }
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                      </Grid>
                      <Typography variant="h2">
                        {pageTranslations?.paymentMethod}
                      </Typography>
                      <FormControl>
                        <RadioGroup
                          value={paymentType}
                          onChange={e => setPaymentType(e.target.value)}
                        >
                          <FormControlLabel
                            value="card"
                            disabled={chineseBookingComplete}
                            control={<Radio color="default" />}
                            label={pageTranslations?.creditCard}
                          />
                          <FormControlLabel
                            value="alipay"
                            disabled={chineseBookingComplete}
                            control={<Radio color="default" />}
                            label={pageTranslations?.aliPay}
                          />
                          <FormControlLabel
                            value="wechat_pay"
                            disabled={chineseBookingComplete}
                            control={<Radio color="default" />}
                            label={pageTranslations?.weChatPay}
                          />
                        </RadioGroup>
                      </FormControl>
                      {paymentType === "card" && (
                        <CardElement options={CARD_ELEMENT_OPTIONS} />
                      )}
                      <Typography className={classes.fineprint}>
                        {pageTranslations?.payments}{" "}
                        <a href="https://www.stripe.com" target="blank">
                          {pageTranslations?.stripe}
                        </a>
                        . {pageTranslations?.cardDetails}{" "}
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a href="#">{pageTranslations?.["t&s"]}.</a>
                      </Typography>
                      <Divider />
                      <Box display="flex" pt={2.5}>
                        {paymentIcons.map(({ childImageSharp }, index) => (
                          <Box key={index} mr={1} clone>
                            <Img fixed={childImageSharp.fixed} />
                          </Box>
                        ))}
                      </Box>
                      <Box pt={mdUp ? 10 : 7} pb={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={state.checkedA}
                              onChange={handlechange("checkedA")}
                              value="checkedA"
                              inputProps={{
                                "aria-label": "primary checkbox",
                              }}
                              color="primary"
                            />
                          }
                          label={
                            <Typography className={classes.checkboxLabel}>
                              {pageTranslations?.promotional}
                            </Typography>
                          }
                          className={classes.checkboxLabel}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={state.checkedB}
                              onChange={handlechange("checkedB")}
                              value="checkedB"
                              inputProps={{
                                "aria-label": "primary checkbox",
                              }}
                              color="primary"
                            />
                          }
                          label={
                            <Typography className={classes.checkboxLabel}>
                              {pageTranslations?.agreeWithZhutravel}{" "}
                              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                              <a href="#">{pageTranslations?.["t&s"]}</a>
                            </Typography>
                          }
                        />
                      </Box>
                      <Divider />
                      <Box
                        pt={2}
                        pb={mdUp ? 12.5 : 2}
                        display="flex"
                        justifyContent="end"
                      >
                        <Button
                          variant="contained"
                          endIcon={
                            loading ? (
                              <CircularProgress size={20} color="secondary" />
                            ) : (
                              <div className={classes.arrow}>
                                <ArrowForward />
                              </div>
                            )
                          }
                          onClick={handleBookNow}
                          disabled={loading || chineseBookingComplete}
                        >
                          {pageTranslations?.bookTour}{" "}
                          {paymentType !== "card" && "And scan QR code to pay"}
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={false} md={2} />
                    <Grid item xs={12} md={4}>
                      <Hidden mdUp>
                        <Typography variant="h1">
                          {pageTranslations?.checkout}
                        </Typography>
                      </Hidden>
                      <Card className={classes.card}>
                        <CardContent>
                          <Typography variant="overline">
                            {pageTranslations?.orderSummary}
                          </Typography>
                          <Typography
                            variant="h2"
                            style={{ paddingTop: "6px", paddingBottom: 0 }}
                          >
                            {data.title}
                          </Typography>
                          <Typography
                            variant="h3"
                            className={classes.cardSection}
                          >
                            {pageTranslations?.itinerary}
                          </Typography>
                          <DatePickerCustom
                            variant={mdUp ? "inline" : "dialog"}
                            open={isOpen}
                            onOpen={() => setIsOpen(true)}
                            onClose={() => setIsOpen(false)}
                            value={data.date}
                            onChange={selectedDate =>
                              setData({ ...data, date: selectedDate })
                            }
                            disablePast
                            autoOk
                            format="E dd LLL yyyy"
                            shouldDisableDate={day => {
                              if (!!data.isDisableCalender) {
                                return true
                              } else {
                                return isDisableDay(day)
                              }
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment>
                                  <CalendarTodayOutlined fontSize="small" />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <div className={classes.inputHelper}>
                                  Change<Hidden smDown> Date</Hidden>
                                </div>
                              ),
                            }}
                          />
                          <Select
                            value={slot}
                            onChange={handleTime}
                            fullWidth
                            open={isOpenSlots}
                            onClose={() => setIsOpenSlots(false)}
                            onOpen={() => setIsOpenSlots(true)}
                            startAdornment={
                              <InputAdornment>
                                <ScheduleIcon fontSize="small" />
                              </InputAdornment>
                            }
                            endAdornment={
                              <div className={classes.inputHelper}>
                                Change<Hidden smDown> Slot</Hidden>
                              </div>
                            }
                            IconComponent="ScheduleIcon"
                          >
                            {data.timesarray &&
                              data.timesarray.map(item => (
                                <MenuItem value={item} key={item}>
                                  {item}
                                </MenuItem>
                              ))}
                          </Select>
                          <PeopleMenu
                            adults={myAdults}
                            myChildren={myChildren}
                            infants={myInfants}
                            setAdults={setAdults}
                            setChildren={setChildren}
                            setInfants={setInfants}
                            translations={translations}
                            hideLabel
                            iconPosition="start"
                            total={data?.personsTotal}
                            perPersonPricing={perPersonPricing}
                            childPricingPercentageToAdult={
                              childPricingPercentageToAdult
                            }
                            totalPersonsLimit={data?.tourAvailability}
                          />
                          {data?.allSelectedExtras?.length > 0 && (
                            <Typography
                              variant="h3"
                              className={classes.cardSection}
                            >
                              {pageTranslations?.extras}
                            </Typography>
                          )}
                          <List dense disablePadding>
                            {data?.allSelectedExtras?.map(extra => (
                              <ListItem
                                key={extra.title}
                                disableGutters
                                alignItems="flex-start"
                              >
                                <ListItemText
                                  primary={extra.title}
                                  secondary={
                                    <Typography
                                      className={classes.unitPrice}
                                    >{`${extra.price} ${pageTranslations?.euro}/${pageTranslations?.adult}`}</Typography>
                                  }
                                />
                                <ListItemText
                                  style={{ flexGrow: 0 }}
                                  primary={
                                    <Typography className={classes.currency}>
                                      {Math.round(
                                        extra.isFixedPrice
                                          ? extra.price
                                          : extra.price *
                                              (myAdults +
                                                (myChildren *
                                                  childPricingPercentageToAdult) /
                                                  100)
                                      )}{" "}
                                      {pageTranslations?.euro}
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            ))}
                          </List>
                          <Typography
                            variant="h3"
                            className={classes.cardSection}
                          >
                            {translations?.availCoupon}
                          </Typography>
                          <Box
                            display="flex"
                            alignItems="flex-end"
                            justifyContent="space-between"
                          >
                            <Box mr={2}>
                              <TextField
                                placeholder={translations?.typeCoupon}
                                value={couponCode}
                                onChange={e => setCouponCode(e.target.value)}
                              />
                            </Box>
                            <Box height={1}>
                              <Button variant="contained" onClick={checkCoupon}>
                                {translations?.apply}
                              </Button>
                            </Box>
                          </Box>
                          <Typography
                            variant="h3"
                            className={classes.cardSection}
                          >
                            {pageTranslations?.total}
                          </Typography>
                          <div className={classes.totalWrapper}>
                            <Typography className={classes.total}>
                              {Math.round(
                                getTotalEur(
                                  myAdults,
                                  myChildren,
                                  perPersonPricing,
                                  childPricingPercentageToAdult,
                                  incrementalExtras,
                                  fixedExtras,
                                  isFixedTour,
                                  fixedTourPrice,
                                  isFixedPriceTour
                                )
                              )}
                            </Typography>
                            <Typography className={classes.currency}>
                              {pageTranslations?.euro}
                            </Typography>
                          </div>
                          <Divider />
                          <Typography className={classes.vat}>
                            {pageTranslations?.vatIncluded}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Box pt={4} pb={3}>
                    <Img fixed={queryData.logo.childImageSharp.fixed} />
                    <Typography
                      style={{ fontSize: "0.75rem", paddingTop: "8px" }}
                    >
                      © 2019
                    </Typography>
                  </Box>
                </Container>
              </ThemeProvider>
            </div>
          </IntlProvider>
        </div>
      </Layout>
    )
  } else {
    typeof window !== "undefined" && navigate("/tours")
    return null
  }
}
export const query = graphql`
  query {
    allCountry {
      edges {
        node {
          name
          alpha3Code
          callingCodes
        }
      }
    }
    visa: file(relativeDirectory: { eq: "cards" }, name: { eq: "visa" }) {
      childImageSharp {
        fixed(height: 32) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    mastercard: file(
      relativeDirectory: { eq: "cards" }
      name: { eq: "master-card" }
    ) {
      childImageSharp {
        fixed(height: 32) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    stripe: file(relativeDirectory: { eq: "cards" }, name: { eq: "stripe" }) {
      childImageSharp {
        fixed(height: 32) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    amex: file(
      relativeDirectory: { eq: "cards" }
      name: { eq: "american-express" }
    ) {
      childImageSharp {
        fixed(height: 32) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    logo: file(name: { eq: "logo-zhutravel-en-cropped" }) {
      childImageSharp {
        fixed(height: 36) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
export default CheckOut
