import React, { useState, useEffect } from "react"
import {
  FormControl,
  FormLabel,
  Input,
  InputAdornment,
  Menu,
  MenuItem,
  Box,
  Typography,
  IconButton,
} from "@material-ui/core"
import PermIdentityIcon from "@material-ui/icons/PermIdentity"
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import { makeStyles } from "@material-ui/core/styles"

const PeopleMenu = ({
  adults,
  myChildren,
  infants,
  setAdults,
  setChildren,
  setInfants,
  translations,
  hideLabel,
  iconPosition,
  total,
  perPersonPricing,
  childPricingPercentageToAdult,
  totalPersonsLimit,
}) => {
  const [peopleMenuOpen, setPeopleMenu] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [menuWidth, setMenuWidth] = useState(null)
  const [newAdults, setNewAdults] = useState(adults)
  const [newChildren, setNewChildren] = useState(myChildren)
  const [newInfants, setNewInfants] = useState(infants)
  const [totalPersons, setTotalPersons] = useState(0)

  useEffect(() => {
    setTotalPersons(newAdults + newChildren + newInfants)
  }, [newAdults, newChildren, newInfants])

  const useStyles = makeStyles({
    noOfPeopleInput: {
      cursor: "pointer",
    },
    peopleMenu: {
      width: menuWidth,
    },
    peopleMenuList: {
      paddingBottom: 0,
    },
    currency: {
      fontSize: 14,
      fontFamily: "MabryProRegular",
      flexShrink: 0,
    },
  })

  const classes = useStyles()

  const noOfPeopleMenu = [
    { label: "Adults (13+ years)", value: newAdults, setter: setNewAdults },
    { label: "Child (4-12 years)", value: newChildren, setter: setNewChildren },
    {
      label: "Infant (under 3 years)",
      value: newInfants,
      setter: setNewInfants,
    },
  ]

  const handleClick = event => {
    setPeopleMenu(true)
    setMenuWidth(event.currentTarget.clientWidth)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setPeopleMenu(false)
    setAdults(newAdults)
    setChildren(newChildren)
    setInfants(newInfants)
  }

  const handleModifyPeople = (newValue, setFunc) => {
    setFunc(newValue)
  }

  return (
    <>
      <FormControl>
        {!hideLabel && <FormLabel>{translations?.noOfPeople}</FormLabel>}
        <Input
          value={`${newAdults} ${translations?.adults}, ${newChildren} ${
            translations?.children
          }${newInfants > 0 ? `, ${newInfants} ${translations?.infants}` : ""}`}
          onClick={event => handleClick(event)}
          readOnly
          inputProps={{ className: classes.noOfPeopleInput }}
          endAdornment={
            iconPosition === "end" ? (
              <InputAdornment position="end">
                <PermIdentityIcon />
              </InputAdornment>
            ) : (
              <div className={classes.currency}>
                {`
                  ${Math.round(
                    perPersonPricing *
                      (newAdults +
                        (newChildren * childPricingPercentageToAdult) / 100)
                  )} ${translations?.euro}
                `}
              </div>
            )
          }
          startAdornment={
            iconPosition === "start" && (
              <InputAdornment>
                <PermIdentityIcon fontSize="small" />
              </InputAdornment>
            )
          }
          className={classes.noOfPeopleInput}
        />
      </FormControl>
      <Menu
        id="people-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={peopleMenuOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: classes.peopleMenu,
          list: classes.peopleMenuList,
        }}
        elevation={1}
      >
        {noOfPeopleMenu.map(({ label, value, setter }, index) => (
          <>
            <MenuItem key={label} button={false} dense>
              <Box width={1}>
                <Typography align="center" style={{ fontSize: "0.875rem" }}>
                  {label}
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  width="100%"
                  justifyContent="space-between"
                  border={index < noOfPeopleMenu.length - 1 ? 1 : 0}
                  borderTop={0}
                  borderLeft={0}
                  borderRight={0}
                  borderColor="divider"
                >
                  <IconButton
                    disabled={value === 0}
                    onClick={() => handleModifyPeople(value - 1, setter)}
                    edge="start"
                    size="small"
                    style={{ padding: "8px", marginLeft: "-8px" }}
                  >
                    <RemoveCircleOutlineIcon fontSize="small" />
                  </IconButton>
                  <Typography
                    align="center"
                    style={{ fontFamily: "MabryProRegular" }}
                  >
                    {value}
                  </Typography>
                  <IconButton
                    disabled={totalPersons >= totalPersonsLimit}
                    onClick={() => handleModifyPeople(value + 1, setter)}
                    edge="end"
                    size="small"
                    style={{ padding: "8px", marginRight: "-8px" }}
                  >
                    <AddCircleOutlineIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            </MenuItem>
          </>
        ))}
      </Menu>
    </>
  )
}

export default PeopleMenu
