import {
  AppBar,
  Button,
  Toolbar,
  Container,
  Typography,
  Hidden,
} from "@material-ui/core"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import { Link } from "gatsby"
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import LocalizedLink from "../LocalizedLink"
import useAppbarStyles from "./CheckoutAppbar.styles"
import { useSelector } from "react-redux"

const CheckoutAppbar = props => {
  const data = useStaticQuery(
    graphql`
      query {
        en: file(name: { eq: "logo-zhutravel-en-cropped" }) {
          childImageSharp {
            fixed(height: 36) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        zh: file(name: { eq: "logo-zhutravel-zh-cropped" }) {
          childImageSharp {
            fixed(height: 36) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  const { pageData: translations, locale } = useSelector(
    state => state?.locale?.localeObj
  )
  const classes = useAppbarStyles()
  // eslint-disable-next-line no-unused-vars
  const [age, setAge] = React.useState("")
  const { compact } = props

  return (
    <Container style={{ padding: "0 16px" }}>
      <AppBar
        position="static"
        className={classes.barLook}
        style={{ backgroundColor: props.backgroundcolor }}
      >
        <Toolbar disableGutters style={compact ? { paddingRight: 8 } : {}}>
          <Hidden mdUp>
            <Button
              component={Link}
              to="/"
              startIcon={
                <div className={classes.arrow}>
                  <ArrowBackIcon />
                </div>
              }
            >
              Back to Zhutravel
            </Button>
          </Hidden>
          <Hidden smDown>
            {/* <Typography
              style={{
                color: props.Textcolor,
                fontWeight: "bold",
                fontFamily: "MabryProBold",
                fontSize: "25px",
                margin: 0,
              }}
              className={classes.nav}
              variant="h6"
              noWrap
              component={Link}
              to="/"
            >
              {translations?.zhutravel}
            </Typography> */}
            <LocalizedLink to="/">
              <Img
                fixed={
                  locale === "zh"
                    ? data.zh.childImageSharp.fixed
                    : data.en.childImageSharp.fixed
                }
              />
            </LocalizedLink>
            <div className={classes.grow} />

            <Button
              variant="outlined"
              component={Link}
              to="/"
              className={classes.btn}
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: "2px",
              }}
              href="/contact"
            >
              <Typography
                style={{
                  fontFamily: "MabryProRegular",
                  fontSize: 16,
                  textTransform: "none",
                }}
              >
                {translations?.backToZhutravel}
              </Typography>
            </Button>
          </Hidden>
        </Toolbar>
      </AppBar>
    </Container>
  )
}

export default CheckoutAppbar
